import moment from "moment";

export const SIDEBAR_WIDTH = 260;

export const YEARS = Array.from(
  { length: new Date().getFullYear() + 1 - 1990 },
  (_, i) => 1990 + 1 + i
);

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const INVOICE_ROLE = [10, 11, 12, 13];
export const INVENTORY_ROLE = [4, 22, 23, 24, 25, 45];
export const PATIENT_CARE_ROLE = [16, 17, 18, 19, 20, 21, 26];
export const WAREHOUSE_INVOICE_ROLE = [41, 42, 43, 44];
export const WAREHOUSE_INVENTORY_ROLE = [27, 30, 31, 32, 33];
export const WAREHOUSE_ROLE = [
  28,
  29,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  ...WAREHOUSE_INVENTORY_ROLE,
  ...WAREHOUSE_INVOICE_ROLE,
];
export const RETUR_ROLE = [46, 47];
export const EXCLUDE_ROLE = [14, 15]; // general patient care & warehouse

export const EXCLUDE_BASIC_PACKAGE = [
  10,
  11,
  12,
  13,
  25,
  ...PATIENT_CARE_ROLE,
  ...WAREHOUSE_ROLE,
];
export const EXCLUDE_PREMIUM_PACKAGE = [17, 18, 19];

export const INITIAL_PAGE_SETTING = "offset=1&limit=50";

export const EMPTY_IMG_SRC =
  "https://staging-olin.sgp1.digitaloceanspaces.com/principal/284f633e50301798413958e9bec3891e4c11c2907d51648d8a8fc2153107ff8d.png";

export const IGNORE_TYPE =
  "Ml mL ml ML Kg KG kg Gram gram GRAM Liter LITER L Mg MG mg g G mcg Mcg MCG Unit UNIT Dosis DOSIS iu Iu iU IU lembar Lembar LEMBAR";

export const dates = [
  {
    value: 1,
    name: "Semua Data",
  },
  {
    value: 2,
    name: "Hari ini",
  },
  {
    value: 3,
    name: "Kemarin",
  },
  {
    value: 4,
    name: "Minggu Berjalan",
  },
  {
    value: 5,
    name: "Minggu Lalu",
  },
  {
    value: 6,
    name: "Bulan Berjalan",
  },
  {
    value: 7,
    name: "Bulan Lalu",
  },
  {
    value: 8,
    name: "Tahun Berjalan",
  },
  {
    value: 9,
    name: "Tahun Lalu",
  },
];

export const week = [
  {
    value: 0,
    day: "Minggu",
  },
  {
    value: 1,
    day: "Senin",
  },
  {
    value: 2,
    day: "Selasa",
  },
  {
    value: 3,
    day: "Rabu",
  },
  {
    value: 4,
    day: "Kamis",
  },
  {
    value: 5,
    day: "Jumat",
  },
  {
    value: 6,
    day: "Sabtu",
  },
];

export const statusTransaction = {
  processed: "#FF8D40",
  cancelledOrComplained: "#F21B3F",
  done: "#00CD52",
  waitingPayment: "#9AA4B2",
};

export const inventoryStatus = {
  1: "Create Product",
  2: "Update Product",
  3: "Stock Opname In",
  4: "Stock Opname Out",
  5: "Stock Adjustment In",
  6: "Stock Adjustment Out",
  7: "Delete Product",
  8: "POS Transaction",
  9: "Generate Invoice",
  10: "Retur Product",
  11: "Allocation",
  12: "Transfer",
  13: "Retur to Warehouse",
  14: "Reject Allocation",
  15: "Reject Transfer",
  16: "Reject Retur",
  17: "Back from Allocation",
  18: "Back from Transfer",
  19: "Back from Retur",
};

export const sortOptions = [
  {
    value: 0,
    name: "Semua Produk",
  },
  {
    value: 1,
    name: "Produk Terbaru",
  },
  {
    value: 2,
    name: "Terakhir diubah",
  },
  {
    value: 3,
    name: "Harga Tertinggi",
  },
  {
    value: 4,
    name: "Harga Terendah",
  },
  {
    value: 5,
    name: "Nama Produk A-Z",
  },
  {
    value: 6,
    name: "Nama Produk Z-A",
  },
  {
    value: 7,
    name: "Stock Terbanyak",
  },
  {
    value: 8,
    name: "Stock Terendah",
  },
];

export const productTypeOptions = [
  {
    id: 1,
    name: "Produk Aktif",
  },

  {
    id: 2,
    name: "Produk Tidak Aktif",
  },

  {
    id: 3,
    name: "Produk Habis",
  },

  {
    id: 4,
    name: "Produk ditahan",
  },

  {
    id: 5,
    name: "Produk Expired",
  },
];

export const transactionDetailStatus = {
  1: "Pending",
  2: "Success",
  3: "Reject",
};

export const transactionDetailStatusColor = {
  1: "#ffae00",
  2: "#23ff00",
  3: "#ff2d00",
};

export const statusOrder = {
  1: "Semua Pesanan",
  2: "Pesanan Baru",
  3: "Siap Dikirim",
  4: "Dalam Pengiriman",
  5: "Pesanan Selesai",
  6: "Dibatalkan Penjual",
  7: "Dibatalkan Sistem",
  8: "Siap Diambil",
};

export const statusOrderColor = {
  2: "#FFB30F",
  3: "#FFB30F",
  4: "#FFB30F",
  5: "#28AFB0",
  6: "#FE654F",
  7: "#FE654F",
  8: "#FFB30F",
};

export const detailStatusMapped = {
  2: [
    {
      title: "Verifikasi Konfirmasi Pembayaran",
      status:
        "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
    },
  ],
  3: [
    {
      title: "Verifikasi Konfirmasi Pembayaran",
      status:
        "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
    },
    {
      title: "Pesanan Diproses",
      status: "Pemesanan sedang diproses oleh penjual.",
    },
  ],
  4: [
    {
      title: "Verifikasi Konfirmasi Pembayaran",
      status:
        "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
    },
    {
      title: "Pesanan Diproses",
      status: "Pemesanan sedang diproses oleh penjual.",
    },
    {
      title: "Pesanan Telah Dikirim",
      status: "Pesanan dalam proses pengiriman oleh kurir.",
    },
  ],
  5: [
    {
      title: "Verifikasi Konfirmasi Pembayaran",
      status:
        "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
    },
    {
      title: "Pesanan Diproses",
      status: "Pemesanan sedang diproses oleh penjual.",
    },
    {
      title: "Pesanan Telah Dikirim",
      status: "Pesanan dalam proses pengiriman oleh kurir.",
    },
    {
      title: "Pesanan Telah Tiba Di tujuan",
      status:
        "Dana akan diteruskan ketika barang dikonfirmasi pembeli atau otomatis dalam 48 jam. Received by ibu yanti resepsionis",
    },
    {
      title: "Transaksi Selesai",
      status: "Dana akan diteruskan ke penjual.",
    },
  ],
  6: [
    {
      title: "Verifikasi Konfirmasi Pembayaran",
      status:
        "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
    },
    {
      title: "Transaksi Ditolak",
      status: "test",
    },
  ],
  7: [
    {
      title: "Verifikasi Konfirmasi Pembayaran",
      status:
        "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
    },
    {
      title: "Transaksi Ditolak",
      status: "Penjual telah melebihi batas waktu pengiriman pesanan",
    },
  ],
};

export const yearFormatBilling = (date) => moment(date).format("YYYY");

export const PPN_OPTION = [
  { value: 0.11, name: "11%" },
  { value: 0.12, name: "12%" },
];

export const PPN_OPTION_INT = [
  { value: 11, name: "11%" },
  { value: 12, name: "12%" },
];
