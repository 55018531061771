import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import moment from "moment";
import { IoMdAddCircleOutline } from "react-icons/io";
import {
  getCustomerDetail,
  getCustomerList,
  getHistoryInvoice,
  getPPNStatus,
  setNewInvoice,
} from "../../../services/invoiceApi";
import { useEffect, useState } from "react";
import { getSipaAndFullname, getUserProfile } from "../../../services/authApi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ModalAddProductInvoice from "./modal/ModalAddProductInvoice";

const FETCH_LIMIT = 10;

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const CreateInvoice = () => {
  const navigate = useNavigate();
  const [searchCustomer, setSearchCustomer] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [offsetHistory, setOffsetHistory] = useState(1);

  const generateRandomString = () => {
    const length = 8;
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const getSubtotal = () => {
    return formik.values.product.length > 0
      ? formik.values.product.reduce(
          (acc, num) =>
            acc + num.quantity * num.price * ((100 - num.discount) / 100),
          0
        )
      : 0;
  };

  const getDppPrice = () => {
    return (
      (formik.values.product.length > 0 ? getSubtotal() : 0) *
      (dataPpn &&
      dataPpn.ppn_value &&
      dataPpn.ppn_value !== 0 &&
      dataPpn.ppn_value * 100 === 12
        ? 11 / 12
        : 1)
    );
  };

  const getPPnPrice = () => {
    return (
      getDppPrice() * (dataPpn && dataPpn.ppn_value ? dataPpn.ppn_value : 0)
    );
  };

  const getGrandTotal = () => {
    return getSubtotal() + getPPnPrice() - formik.values.voucher;
  };

  const { data: profile } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getUserProfile(),
  });

  const { data: dataCustomer, isFetching } = useQuery({
    queryKey: ["customer-list", searchCustomer],
    queryFn: () => getCustomerList(FETCH_LIMIT, 1, searchCustomer, false),
  });

  const { data: dataPpn } = useQuery({
    queryFn: () => getPPNStatus(false),
  });

  const { data: dataDiscountCustomer, isFetching: isFetchingDiscountCustomer } =
    useQuery({
      queryKey: ["customer-detail", selectedCustomer],
      enabled: selectedCustomer !== undefined && selectedCustomer !== null,
      queryFn: () => getCustomerDetail(selectedCustomer.partner_id, false),
    });

  const { data: sipaAndFullnameData, isFetching: isFetchingSipa } = useQuery({
    queryKey: ["sipa-fullname", profile?.id],
    queryFn: () =>
      getSipaAndFullname(profile && profile !== undefined ? profile.id : 0),
  });

  const formik = useFormik({
    initialValues: {
      invoice_date: moment().format("YYYY-MM-DD"),
      due_date: moment().add(30, "d").format("YYYY-MM-DD"),
      invoice_number:
        "INV/" + moment().format("YYYY") + "/" + generateRandomString(),
      product: [],
      customer_id: 0,
      sales_code: "",
      warehouse: false,
      faktur_number: "",
      voucher: 0,
      dpp: 0,
    },
    onSubmit: (values) => {
      mutateNewInvoice(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue("dpp", getDppPrice());
  }, [formik.values.product]);

  const { mutate: mutateNewInvoice, isLoading } = useMutation({
    mutationFn: setNewInvoice,
    onSuccess: () => navigate(`/invoice/invoice`),
  });

  const {
    mutate: mutateHistory,
    isLoading: isLoadingHistory,
    data: dataHistory,
  } = useMutation({
    mutationFn: getHistoryInvoice,
  });

  useEffect(() => {
    var temp = [...formik.values.product];
    temp.map((item, i) => {
      temp[i].discount =
        dataDiscountCustomer && selectedCustomer
          ? dataDiscountCustomer.discount
          : 0;
    });
    formik.setFieldValue(
      "product",
      dataDiscountCustomer ? temp : formik.values.product
    );
  }, [dataDiscountCustomer]);

  useEffect(() => {
    if (selectedCustomer) {
      mutateHistory({
        customer_id: selectedCustomer.partner_id,
        offset: offsetHistory,
        limit: 1,
      });
    }
  }, [selectedCustomer, offsetHistory]);

  return (
    <div className="text-[12px]">
      <div className="flex items-center gap-2 mb-4">
        <DesktopDatePicker
          label="Tanggal Invoice"
          value={
            formik.values.invoice_date === ""
              ? null
              : formik.values.invoice_date
          }
          onChange={(date) =>
            formik.setFieldValue(
              "invoice_date",
              date ? moment(date).format("YYYY-MM-DD") : ""
            )
          }
          sx={{ width: "100%" }}
          renderInput={(params) => {
            return <TextField fullWidth {...params} />;
          }}
        />
        <TextField
          label="Nomor Faktur Pajak"
          value={formik.values.faktur_number}
          onChange={(e) =>
            formik.setFieldValue("faktur_number", e.target.value)
          }
          fullWidth
        />
        <DesktopDatePicker
          label="Jatuh Tempo"
          value={formik.values.due_date === "" ? null : formik.values.due_date}
          minDate={moment(formik.values.invoice_date).add(1, "d")}
          onChange={(date) =>
            formik.setFieldValue(
              "due_date",
              date ? moment(date).format("YYYY-MM-DD") : ""
            )
          }
          sx={{ width: "100%" }}
          renderInput={(params) => {
            return <TextField fullWidth {...params} />;
          }}
        />
        <Autocomplete
          fullWidth
          loading={isFetching}
          disablePortal
          options={
            dataCustomer && dataCustomer.data !== null ? dataCustomer.data : []
          }
          value={selectedCustomer}
          onChange={(_, inputValue) => {
            setOffsetHistory(1);
            setSelectedCustomer(inputValue);
            formik.setFieldValue(
              "customer_id",
              inputValue === null ? 0 : inputValue.partner_id
            );
          }}
          onInputChange={(_, onInputValue, reason) => {
            if (reason === "input") {
              setSearchCustomer(onInputValue);
            }
          }}
          getOptionLabel={(option) => option.partner_name || ""}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.partner_id}>
                {option.partner_name}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Customer" />
          )}
        />
        <TextField
          label="Kode Sales"
          value={formik.values.sales_code}
          onChange={(e) => formik.setFieldValue("sales_code", e.target.value)}
          fullWidth
        />
        <button
          type="button"
          className="border rounded-md flex items-center justify-between gap-2 w-fit py-2.5 px-2 bg-blue-500 text-white hover:bg-gray-300 whitespace-nowrap text-nowrap"
          onClick={() => setOpenAdd(true)}
        >
          Tambah Produk
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>

      <div className="block lg:flex mb-4 items-start gap-4">
        <div className="w-full h-full">
          <div className="w-full flex gap-2 font-bold mb-4 items-center">
            <p className="w-full">
              Customer Diskon :{"  "}
              {isFetchingDiscountCustomer ? (
                <CircularProgress size={15} />
              ) : dataDiscountCustomer && selectedCustomer ? (
                dataDiscountCustomer.discount
              ) : (
                ""
              )}
            </p>
            <p className="w-full">
              Nama Apoteker : {"  "}
              {isFetchingSipa ? (
                <CircularProgress size={15} />
              ) : sipaAndFullnameData && sipaAndFullnameData !== undefined ? (
                sipaAndFullnameData.fullname
              ) : (
                ""
              )}
            </p>
            <p className="w-full">
              No. SIPA : {"  "}
              {isFetchingSipa ? (
                <CircularProgress size={15} />
              ) : sipaAndFullnameData && sipaAndFullnameData !== undefined ? (
                sipaAndFullnameData.sipa_id
              ) : (
                ""
              )}
            </p>
          </div>

          <div className="flex w-full mb-8 min-h-[40vh]">
            <div className="w-full">
              <div className="block overflow-x-auto w-full rounded-md">
                <table className="w-full overflow-x-auto rounded-md border-collapse">
                  <thead className="bg-blue-500 text-white">
                    <th className="p-2 text-center">Nama Produk</th>
                    <th className="p-2 text-center">Kuantitas</th>
                    <th className="p-2 text-center">Batch</th>
                    <th className="p-2 text-center">Expired</th>
                    <th className="p-2 text-center">Harga Satuan</th>
                    <th className="p-2 text-center w-[200px]">Diskon</th>
                    <th className="p-2 text-center">Promo</th>
                    <th className="p-2 text-center">Total</th>
                    <th className="p-2 text-center">Total + Diskon</th>
                  </thead>
                  <tbody className="border">
                    {formik.values.product.length > 0 ? (
                      formik.values.product.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            className={`${i === 0 ? "" : "border-t"}`}
                          >
                            <td className="p-2 text-center">
                              {item.product_name}
                            </td>
                            <td className="p-2 text-center">
                              {item.quantity}{" "}
                              {item.type_uom === 0
                                ? item.product_units.primary_type
                                : item.product_units.secondary_type}
                            </td>
                            <td className="p-2 text-center">
                              {item.batch_number}
                            </td>
                            <td className="p-2 text-center">
                              {moment(item.expired_date).format("DD/MM/YYYY")}
                            </td>
                            <td className="p-2 text-center">
                              {toIDR(item.price)}
                            </td>
                            <td className="p-2 text-center">
                              <div className="flex items-center gap-2">
                                <TextField
                                  fullWidth
                                  type="number"
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                  value={item.discount.toString()}
                                  sx={{ minWidth: "100px" }}
                                  onChange={(e) => {
                                    if (!isNaN(Number(e.target.value))) {
                                      formik.setValues({
                                        ...formik.values,
                                        product: [
                                          ...formik.values.product.slice(0, i),
                                          {
                                            ...formik.values.product[i],
                                            discount:
                                              Number(e.target.value) > 100
                                                ? 100
                                                : Number(e.target.value),
                                          },
                                          ...formik.values.product.slice(i + 1),
                                        ],
                                      });
                                    }
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </td>
                            <td className="p-2 text-center">
                              {item.promo === "" ? "-" : item.promo}
                            </td>
                            <td className="p-2 text-center">
                              {toIDR(item.quantity * item.price)}
                            </td>
                            <td className="p-2 text-center">
                              {toIDR(
                                item.quantity *
                                  item.price *
                                  ((100 - item.discount) / 100)
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="p-2 text-center" colSpan={9}>
                          Tidak ada produk
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {formik.values.product.length > 0 ? (
            <div className="w-full flex items-end justify-end mb-8">
              <div className="w-1/2">
                <div className="flex gap-2 items-center mb-2">
                  <p className="w-1/2">Jumlah</p>
                  <p>:</p>
                  <p className="w-full">
                    {toIDR(
                      formik.values.product.length > 0
                        ? formik.values.product.reduce(
                            (acc, num) => acc + num.quantity * num.price,
                            // ((100 - num.discount) / 100),
                            0
                          )
                        : 0
                    )}
                  </p>
                </div>
                <div className="flex gap-2 items-center mb-2">
                  <p className="w-1/2">Diskon</p>
                  <p>:</p>
                  <p className="w-full">
                    {toIDR(
                      formik.values.product.length > 0
                        ? formik.values.product.reduce(
                            (acc, num) =>
                              acc +
                              num.quantity * num.price * (num.discount / 100),
                            0
                          )
                        : 0
                    )}
                  </p>
                </div>
                <div className="flex gap-2 items-center mb-2">
                  <p className="w-1/2">Subtotal</p>
                  <p>:</p>
                  <p className="w-full">
                    {toIDR(
                      formik.values.product.length > 0 ? getSubtotal() : 0
                    )}
                  </p>
                </div>
                <div className="flex gap-2 items-center mb-2">
                  <p className="w-1/2">PPN</p>
                  <p>:</p>
                  <p className="w-full">{toIDR(getPPnPrice())}</p>
                </div>{" "}
                <div className="flex gap-2 items-center mb-2">
                  <p className="w-1/2">DPP</p>
                  <p>:</p>
                  <p className="w-full">{toIDR(getDppPrice())}</p>
                </div>
                <div className="flex gap-2 items-center mb-2">
                  <p className="w-1/2">Voucher</p>
                  <p>:</p>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rp</InputAdornment>
                      ),
                    }}
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    value={formik.values.voucher.toString()}
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        formik.setValues({
                          ...formik.values,
                          voucher: Number(e.target.value),
                        });
                      }
                    }}
                  />
                </div>
                <div className="flex gap-2 items-center mb-2 bg-blue-100 text-blue-500 p-1 rounded-md font-bold">
                  <p className="w-1/2">Jumlah Bayar</p>
                  <p>:</p>
                  <p className="w-full">
                    {toIDR(getGrandTotal() < 0 ? 0 : getGrandTotal())}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="w-full flex items-end justify-end mb-4">
            <div className="w-1/2 flex gap-4 items-center">
              <button
                type="button"
                className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
                onClick={() => navigate(`/invoice/invoice`)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="w-full p-2 rounded-md border-2 border-blue-500 bg-blue-500 text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300 disabled:border-none"
                onClick={() => formik.handleSubmit()}
                disabled={
                  formik.values.product.length === 0 ||
                  formik.values.customer_id === 0 ||
                  isLoading ||
                  !formik.values.customer_id
                }
              >
                {isLoading ? <CircularProgress size={10} /> : "Save"}
              </button>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="border-2 rounded-md p-3 h-full">
            <p className="font-bold mb-2 text-[14px]">
              Riwayat Faktur Pelanggan
            </p>
            <div className="flex justify-end w-full mb-2">
              <div className="flex gap-4 justify-between items-center w-3/4">
                <button
                  type="button"
                  className="border rounded-md p-1 disabled:bg-gray-300 disabled:text-white w-[32px] h-[32px] flex items-center justify-center"
                  disabled={
                    offsetHistory === 1 || !dataHistory || isLoadingHistory
                  }
                  onClick={() => setOffsetHistory(offsetHistory - 1)}
                >
                  <FaChevronLeft />
                </button>
                <p>
                  {selectedCustomer && dataHistory && dataHistory.data ? (
                    <>
                      {moment(dataHistory.data[0]?.history_date).format(
                        "DD MMMM YYYY"
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </p>
                <button
                  type="button"
                  className="border rounded-md p-1 disabled:bg-gray-300 disabled:text-white w-[32px] h-[32px] flex items-center justify-center"
                  disabled={
                    (dataHistory && offsetHistory === dataHistory.count) ||
                    !dataHistory ||
                    isLoadingHistory
                  }
                  onClick={() => setOffsetHistory(offsetHistory + 1)}
                >
                  <FaChevronRight />
                </button>
              </div>
            </div>
            <hr className="my-2" />
            {selectedCustomer &&
            dataHistory &&
            !isLoadingHistory &&
            dataHistory.data &&
            dataHistory.count > 0 ? (
              <>
                <div className="mb-2">
                  <div className="w-full flex gap-2 items-center">
                    <p className="w-1/3">No. Faktur</p>
                    <p>:</p>
                    <p>{dataHistory.data[0]?.faktur_number}</p>
                  </div>
                  <div className="w-full flex gap-2 items-center">
                    <p className="w-1/3">Total Bayar</p>
                    <p>:</p>
                    <p>{toIDR(dataHistory.data[0]?.total_payment)}</p>
                  </div>
                  <div className="w-full flex gap-2 items-center">
                    <p className="w-1/3">Jatuh Tempo</p>
                    <p>:</p>
                    <p>
                      {dataHistory.data[0]?.due_date === ""
                        ? "-"
                        : moment(dataHistory.data[0]?.due_date).format(
                            "DD-MM-YYYY"
                          )}
                    </p>
                  </div>
                  <div className="w-full flex gap-2 items-center">
                    <p className="w-1/3">Tanggal Pembayaran</p>
                    <p>:</p>
                    <p>
                      {dataHistory.data[0]?.payment_date === ""
                        ? "-"
                        : moment(dataHistory.data[0]?.payment_date).format(
                            "DD-MM-YYYY"
                          )}
                    </p>
                  </div>
                  <div className="w-full flex gap-2 items-center">
                    <p className="w-1/3">Metode Pembayaran</p>
                    <p>:</p>
                    <p>{dataHistory.data[0]?.payment_method}</p>
                  </div>
                  <div className="w-full flex gap-2 items-center">
                    <p className="w-1/3">Status</p>
                    <p>:</p>
                    <p>
                      {dataHistory.data[0]?.status === 1
                        ? "Belum Dibayar"
                        : dataHistory.data[0]?.status === 2
                        ? "Belum Lunas"
                        : dataHistory.data[0]?.status === 3
                        ? "Lunas"
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="max-h-[50vh] overflow-y-auto w-full bg-gray-100 rounded-md p-3 mb-4">
                  {dataHistory.data[0]?.product.map((item, i) => (
                    <div
                      className={`bg-white rounded-md py-2 px-4 ${
                        i === dataHistory.data[0]?.product.length - 1
                          ? ""
                          : "mb-3"
                      }`}
                      key={i}
                    >
                      <div className="mb-2">
                        <div className="w-full flex gap-2">
                          <p className="w-full">Nama Produk</p>
                          <p className="w-full">Total</p>
                        </div>
                        <div className="w-full flex gap-2 text-[14px] font-bold">
                          <p className="w-full">{item.product_name}</p>
                          <p className="w-full">{toIDR(item.sub_price)}</p>
                        </div>
                      </div>
                      <div className="mb-2">
                        <div className="w-full flex gap-2">
                          <p className="w-full">Kuantitas</p>
                          <p className="w-full">Diskon</p>
                        </div>
                        <div className="w-full flex gap-2 text-[14px] font-bold">
                          <p className="w-full">{item.quantity}</p>
                          <p className="w-full">{item.discount}%</p>
                        </div>
                      </div>
                      <div className="mb-2">
                        <div className="w-full flex gap-2">
                          <p className="w-full">Harga Satuan</p>
                          <p className="w-full">Total + Diskon</p>
                        </div>
                        <div className="w-full flex gap-2 text-[14px] font-bold">
                          <p className="w-full">{toIDR(item.unit_price)}</p>
                          <p className="w-full">{toIDR(item.total_price)}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="w-full flex justify-end">
                  <div className="w-2/3">
                    <div className="w-full flex gap-2 items-center">
                      <p className="w-1/2">Jumlah</p>
                      <p>:</p>
                      <p>{toIDR(dataHistory.data[0]?.total_amount)}</p>
                    </div>
                    <div className="w-full flex gap-2 items-center">
                      <p className="w-1/2">Diskon</p>
                      <p>:</p>
                      <p>{toIDR(dataHistory.data[0]?.total_discount)}</p>
                    </div>
                    <div className="w-full flex gap-2 items-center">
                      <p className="w-1/2">Subtotal</p>
                      <p>:</p>
                      <p>{toIDR(dataHistory.data[0]?.sub_total)}</p>
                    </div>
                    <div className="w-full flex gap-2 items-center">
                      <p className="w-1/2">PPN</p>
                      <p>:</p>
                      <p>{toIDR(dataHistory.data[0]?.ppn)}</p>
                    </div>
                    <div className="w-full flex gap-2 items-center">
                      <p className="w-1/2">DPP</p>
                      <p>:</p>
                      <p>
                        {toIDR(
                          dataHistory.data[0]?.dpp
                            ? dataHistory.data[0]?.dpp
                            : dataHistory.data[0]?.sub_total
                        )}
                      </p>
                    </div>
                    <div className="w-full flex gap-2 items-center">
                      <p className="w-1/2">Voucher</p>
                      <p>:</p>
                      <p>{toIDR(dataHistory.data[0]?.voucher)}</p>
                    </div>
                    <div className="w-full flex gap-2 items-center font-bold">
                      <p className="w-1/2">Jumlah Bayar</p>
                      <p>:</p>
                      <p>{toIDR(dataHistory.data[0]?.total)}</p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="h-full min-h-[55vh] flex items-center justify-center text-gray-400 text-center w-full">
                {isLoadingHistory ? (
                  <CircularProgress size={20} />
                ) : (
                  <>
                    Silahkan isi form tambah faktur <br />
                    terlebih dahulu untuk melihat riwayat
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalAddProductInvoice
        open={openAdd}
        setOpen={setOpenAdd}
        formik={formik}
        discountCustomer={
          dataDiscountCustomer && selectedCustomer
            ? dataDiscountCustomer.discount
            : 0
        }
      />
    </div>
  );
};

export default CreateInvoice;
