import { useState, useEffect, useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AiFillCheckCircle, AiOutlineArrowLeft } from "react-icons/ai";
import { TbTicket } from "react-icons/tb";
import { BsCreditCard2Back } from "react-icons/bs";

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Snackbar,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import { useParams } from "react-router-dom";

import { getDetailCheckout } from "../../../services/authApi";
import PaymentMethodCheckout from "./PaymentMethodCheckout";
import {
  getPaymentChannel,
  getUseVoucher,
  getVoucherSubscription,
  createTransactionSubs,
} from "../../../services/paymentApi";
import VoucherSubscription from "./VoucherSubscription";
import VoucherDetail from "./VoucherDetail";
import useDebounce from "../../hooks/useDebounce";

const CheckoutSubscription = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const [trigger, setTrigger] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    paymentId: [],
    paymentCode: "",
    paymentImg: "",
    paymentBank: "",
    paymentBankFee: 0,
    paymentTypeFee: 0,
    isConfirmed: false,
  });

  const [selectedPromo, setSelectedPromo] = useState({
    voucherId: [],
    voucherCode: "",
    discount: 0,
    isConfirmed: false,
  });

  const [isOpenPaymentMethod, setIsOpenPaymentMethod] = useState(false);
  const [isOpenAddVoucher, setIsOpenAddVoucher] = useState(false);

  const [isDetailVoucherOpen, setIsDetailVoucherOpen] = useState({
    voucherId: "",
    state: false,
  });

  const [paymentConfirmed, setPaymentConfirmed] = useState({
    price: 0,
    type_subs: null,
    type_payment_method: 1,
    subscription_id: parseInt(id, 10),
    name_subscription: "",
    code_voucher: "",
    amount_voucher: 0,
    fee_services: 0,
    total_price: 0,
    payment: {
      pg_name: "",
      pg_bank: "",
      pg_code: "",
      pg_type: "",
    },
  });

  const debounce = useDebounce(selectedPromo.voucherCode, 300);

  const { data: paymentChannel, isLoading: isPaymentChannelLoading } = useQuery(
    {
      queryKey: ["payment-channel"],
      queryFn: () => getPaymentChannel(1),
    }
  );

  const { mutate: mutateCreateTransaction } = useMutation({
    mutationFn: createTransactionSubs,
    onSuccess: (data) => {
      navigate("/subscription/purchase");
      sessionStorage.setItem("payment", JSON.stringify(data));
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const { data: subscriptionDetail } = useQuery({
    queryKey: ["subs-detail"],
    queryFn: () => getDetailCheckout(id),
  });

  const { data: voucher } = useQuery({
    queryKey: ["voucher"],
    queryFn: () =>
      getVoucherSubscription(
        subscriptionDetail?.id,
        id,
        selectedPaymentMethod.paymentCode
      ),
    enabled: selectedPaymentMethod.paymentCode === "",
  });

  const { data: selectedVoucher } = useQuery({
    queryKey: ["use-voucher", selectedPaymentMethod.paymentCode, debounce],
    queryFn: () =>
      getUseVoucher(
        subscriptionDetail?.id,
        id,
        selectedPaymentMethod.paymentCode,
        debounce
      ),
    enabled: !!debounce && !!selectedPaymentMethod.paymentCode,
    refetchOnWindowFocus: false,
    retry: false,
    onError: () => {
      setTrigger(true);
    },
    onSuccess: () => {
      setTrigger(false);
      setSelectedPromo({ ...selectedPromo, isConfirmed: true });
    },
  });

  const totalPrice = useMemo(() => {
    if (subscriptionDetail && subscriptionDetail !== undefined) {
      let finalPrice = 0;
      let priceMinusDiscount =
        subscriptionDetail.price_after_discount - selectedPromo.discount;
      // let tax = priceMinusDiscount * 0.11;
      if (selectedPaymentMethod.paymentTypeFee === 1)
        finalPrice =
          //(priceMinusDiscount + tax) / selectedPaymentMethod.paymentBankFee;
          priceMinusDiscount / selectedPaymentMethod.paymentBankFee;
      else if (selectedPaymentMethod.paymentTypeFee === 2)
        finalPrice =
          //          priceMinusDiscount + tax + selectedPaymentMethod.paymentBankFee;
          priceMinusDiscount + selectedPaymentMethod.paymentBankFee;
      //else return subscriptionDetail.price + tax;
      else return subscriptionDetail.price;

      return finalPrice;
    }

    return 0;
  }, [subscriptionDetail, selectedPaymentMethod, selectedVoucher]);

  const tax = useMemo(() => {
    if (subscriptionDetail && subscriptionDetail !== undefined) {
      let priceMinusDiscount =
        subscriptionDetail.price_after_discount - selectedPromo.discount;
      let tax = priceMinusDiscount * 0.11;
      return tax;
    }

    return 0;
  }, [subscriptionDetail, selectedVoucher]);

  const handlingFee = useMemo(() => {
    if (subscriptionDetail && subscriptionDetail !== undefined) {
      let finalPrice = 0;
      let priceMinusDiscount =
        subscriptionDetail.price_after_discount - selectedPromo.discount;
      // let tax = priceMinusDiscount * 0.11;
      if (selectedPaymentMethod.paymentTypeFee === 1) {
        finalPrice =
          // (priceMinusDiscount + tax) / selectedPaymentMethod.paymentBankFee;
          priceMinusDiscount / selectedPaymentMethod.paymentBankFee;
      }
      // return finalPrice - (priceMinusDiscount + tax);
      return finalPrice - priceMinusDiscount;
    }
  }, [totalPrice, subscriptionDetail, selectedPaymentMethod, selectedVoucher]);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setErrorMessage("");
  };

  useEffect(() => {
    if (selectedVoucher && selectedVoucher !== undefined)
      setSelectedPromo({
        voucherId: [selectedVoucher.id],
        voucherCode: selectedVoucher.voucher_code,
        discount: selectedVoucher.total_discount_calculate,
        isConfirmed: true,
      });
    else
      setSelectedPromo({
        voucherId: [],
        voucherCode: "",
        discount: 0,
        isConfirmed: false,
      });
  }, [selectedVoucher]);

  useEffect(() => {
    setPaymentConfirmed({
      ...paymentConfirmed,
      name_subscription:
        subscriptionDetail && subscriptionDetail !== undefined
          ? subscriptionDetail.name
          : "",
      price:
        subscriptionDetail && subscriptionDetail !== undefined
          ? subscriptionDetail.price_after_discount
          : "",
      total_price: Math.round(totalPrice),
    });
  }, [totalPrice]);

  return (
    <div>
      <>
        {subscriptionDetail && subscriptionDetail !== undefined ? (
          <>
            <div className="w-full mt-3 p-2 rounded-lg border border-black">
              <p className="text-[20px] font-bold">Olin Subscription</p>
              <p className="text-[#007AF1] text-[12px] font-bold">
                {subscriptionDetail.name}
              </p>
              <p className="font-bold">
                {subscriptionDetail.price_after_discount.toLocaleString(
                  "id-ID",
                  {
                    style: "currency",
                    currency: "IDR",
                  }
                )}{" "}
                /{subscriptionDetail.periode_name}
              </p>
              <div className="border border-black my-3"></div>
              {subscriptionDetail.description.map((desc, index) => (
                <div className="flex items-center" key={index}>
                  <AiFillCheckCircle className="mr-2 text-[#407BFF] text-[25px]" />
                  <p>{desc}</p>
                </div>
              ))}
            </div>
            <div className="w-full mt-3 p-2 rounded-lg border border-black flex items-center justify-between">
              <div className="flex items-center">
                {paymentChannel &&
                paymentChannel !== undefined &&
                !selectedPaymentMethod.isConfirmed ? (
                  <>
                    <BsCreditCard2Back className="text-[25px] mr-3" />
                    <p className="text-[16px] font-bold">
                      Pilih Metode Pembayaran
                    </p>
                  </>
                ) : (
                  <>
                    <div className="flex w-[10%] mr-3">
                      <img src={selectedPaymentMethod.paymentImg} />
                    </div>
                    <div className="flex justify-between items-center w-full font-bold">
                      <p>{selectedPaymentMethod.paymentBank}</p>
                    </div>
                  </>
                )}
              </div>
              <p
                className="text-[#007AF1] hover:cursor-pointer"
                onClick={() => setIsOpenPaymentMethod(true)}
              >
                Pilih
              </p>
            </div>
            <div
              className={`w-full mt-3 p-2 rounded-lg border border-black flex items-center justify-between ${
                selectedPaymentMethod.isConfirmed ? "bg-white" : "bg-gray-200"
              }`}
            >
              {selectedVoucher && selectedVoucher !== undefined ? (
                <div className="flex items-center">
                  <div className="flex w-[10%] mr-3">
                    <img src={selectedVoucher.image} />
                  </div>
                  <p className="text-[12px] font-bold w-full">
                    {selectedVoucher.voucher_name}
                  </p>
                  <p className="text-[12px] font-bold text-[#007AF1] w-full">
                    Hemat{" "}
                    {selectedVoucher.total_discount_calculate.toLocaleString(
                      "id-ID",
                      {
                        style: "currency",
                        currency: "IDR",
                      }
                    )}
                  </p>
                </div>
              ) : (
                <div className="flex items-center">
                  <TbTicket className="text-[25px] mr-3" />
                  <p className="text-[16px] font-bold">
                    Tambah Coupon / Voucher
                  </p>
                </div>
              )}
              <p
                className="text-[#007AF1] hover:cursor-pointer"
                onClick={() => {
                  if (
                    selectedPaymentMethod.paymentImg === "" &&
                    selectedPaymentMethod.paymentBank === ""
                  )
                    return;
                  setIsOpenAddVoucher(true);
                }}
              >
                Tambah
              </p>
            </div>
            <div className="border-2 border-dashed mt-3"></div>
            <p className="my-4 font-bold">Detail Harga</p>
            <div className="font-bold flex justify-between">
              <div>
                <p>OLIN Subscription</p>
                <p className="my-3">Voucher Discount</p>
                <p>Biaya Penanganan</p>
                {/*<p className="mt-3">PPN</p>*/}
              </div>
              <div>
                <p>
                  {subscriptionDetail.price_after_discount.toLocaleString(
                    "id-ID",
                    {
                      style: "currency",
                      currency: "IDR",
                    }
                  )}
                </p>
                <p className="my-3">
                  {selectedVoucher
                    ? selectedVoucher.max_total_discount.toLocaleString(
                        "id-ID",
                        {
                          style: "currency",
                          currency: "IDR",
                        }
                      )
                    : 0}
                </p>
                {selectedPaymentMethod.paymentTypeFee === 1 ? (
                  <p>
                    {Math.floor(handlingFee).toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </p>
                ) : (
                  <p>
                    {selectedPaymentMethod.paymentBankFee.toLocaleString(
                      "id-ID",
                      {
                        style: "currency",
                        currency: "IDR",
                      }
                    )}
                  </p>
                )}
                {/*<p className="my-3">{tax.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}</p>*/}
              </div>
            </div>
            <div className="border border-black my-3"></div>
            <div className="flex font-bold justify-between">
              <p className="text-[14px]">Total Harga</p>
              <p>
                {Math.floor(totalPrice).toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>
            <button
              className="bg-[#007AF1] p-2 text-white w-full font-bold rounded-md mt-3 disabled:bg-gray-100"
              disabled={selectedPaymentMethod.paymentId.length === 0}
              onClick={() => mutateCreateTransaction(paymentConfirmed)}
            >
              Bayar
            </button>
          </>
        ) : null}
      </>
      <Dialog
        open={isOpenPaymentMethod}
        onClose={() => {
          if (!selectedPaymentMethod.isConfirmed) return;
          setIsOpenPaymentMethod(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <div className="flex items-center w-full justify-center">
            <button
              onClick={() => {
                setIsOpenPaymentMethod(false);
                setSelectedPaymentMethod({
                  ...selectedPaymentMethod,
                  isConfirmed: false,
                });
              }}
            >
              <AiOutlineArrowLeft className="font-bold text-start text-[25px] hover:cursor-pointer" />
            </button>
            <div className="flex justify-center w-full">
              <p>Metode Pembayaran</p>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <PaymentMethodCheckout
            paymentChannel={paymentChannel}
            isPaymentChannelLoading={isPaymentChannelLoading}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            selectedPaymentMethod={selectedPaymentMethod}
            paymentConfirmed={paymentConfirmed}
            setPaymentConfirmed={setPaymentConfirmed}
          />
        </DialogContent>
        <DialogActions>
          <div className="w-full">
            <button
              className="bg-blue-500 p-2 rounded-md text-white font-bold w-full"
              onClick={() => {
                if (!selectedPaymentMethod.isConfirmed) return;
                setIsOpenPaymentMethod(false);
              }}
            >
              Konfirmasi
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpenAddVoucher && selectedPaymentMethod.paymentCode !== ""}
        onClose={() => {
          setIsOpenAddVoucher(false);
          setIsDetailVoucherOpen({ ...isDetailVoucherOpen, state: false });
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={() =>
              setIsDetailVoucherOpen({ ...isDetailVoucherOpen, state: false })
            }
          >
            <AiOutlineArrowLeft />
          </button>
          <div className="flex w-full items-center justify-center">
            <p className="font-bold">
              {isDetailVoucherOpen.state
                ? "Detail Promo"
                : "Voucher / Kode Promo"}
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          {isDetailVoucherOpen.state ? (
            <VoucherDetail
              setIsDetailVoucherOpen={setIsDetailVoucherOpen}
              isDetailVoucherOpen={isDetailVoucherOpen}
            />
          ) : (
            <VoucherSubscription
              isDetailVoucherOpen={isDetailVoucherOpen}
              trigger={trigger}
              setTrigger={setTrigger}
              errorMessage={errorMessage}
              setIsDetailVoucherOpen={setIsDetailVoucherOpen}
              voucher={voucher}
              selectedVoucher={selectedVoucher}
              selectedPromo={selectedPromo}
              setSelectedPromo={setSelectedPromo}
              paymentConfirmed={paymentConfirmed}
              setPaymentConfirmed={setPaymentConfirmed}
              setIsOpenAddVoucher={setIsOpenAddVoucher}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{ display: isDetailVoucherOpen.state ? "none" : null }}
        >
          <div className="w-full flex">
            {selectedVoucher && selectedVoucher !== undefined ? (
              <div className="flex justify-between w-full">
                <div className="text-[12px] font-bold w-full">
                  <p>Kamu bisa hemat</p>
                  <p className="text-[#007AF1]">
                    {selectedVoucher.total_discount_calculate.toLocaleString(
                      "id-ID",
                      {
                        style: "currency",
                        currency: "IDR",
                      }
                    )}
                  </p>
                </div>
                <button
                  className="bg-blue-500 p-2 rounded-md text-white font-bold w-full"
                  onClick={() => {
                    if (selectedVoucher && selectedVoucher !== undefined) {
                      setSelectedPromo({
                        voucherId: [selectedVoucher.id],
                        voucherCode: selectedVoucher.voucher_code,
                        discount: selectedVoucher.total_discount_calculate,
                        isConfirmed: true,
                      });
                      setIsOpenAddVoucher(false);
                    } else return;
                  }}
                >
                  Gunakan Voucher
                </button>
              </div>
            ) : (
              <button
                className="bg-blue-500 p-2 rounded-md text-white font-bold w-full"
                onClick={() => {
                  setIsOpenAddVoucher(false);
                  setSelectedPromo({
                    ...selectedPromo,
                    voucherId: [],
                    voucherCode: "",
                    discount: 0,
                    isConfirmed: false,
                  });
                }}
              >
                Bayar Tanpa Voucher
              </button>
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default CheckoutSubscription;
