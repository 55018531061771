import React from "react";
import Error from ".././assets/images/404.svg";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/features/auth/authSlice";
import { useDispatch } from "react-redux";

const FreezeAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const statusUser = localStorage.getItem("status_code_user");
  const statusCompany = localStorage.getItem("status_code");

  return (
    <div className=" bg-[#63616B]">
      <div
        className="
            flex flex-col items-center justify-center h-screen text-gray-700"
      >
        <img src={Error} alt="404" className="w-1/2" />
        <div
          className="absolute 
                top-[60%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
        >
          <h1 className="text-center text-[32px] lg:text-[48px] font-bold mb-4">
            Status Akun{" "}
            {Number(statusCompany) === 2
              ? "Ditolak"
              : Number(statusCompany) === 3
              ? "Dibekukan"
              : ""}
          </h1>
          {Number(statusCompany) === 2 ? (
            <p className="text-center text-[12px] lg:text-[20px]">
              Status apotek ditolak oleh tim OLIN.
              <br />
              Silahkan memperbaharui data anda melalui aplikasi.
            </p>
          ) : Number(statusCompany) === 3 ? (
            <p className="text-center text-[12px] lg:text-[20px]">
              Status apotek dibekukan karena belum memiliki subscription. <br />
              Silahkan perbaharui subscription agar dapat menggunakan OLIN
              kembali.
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="flex justify-center items-center mt-10">
          <button
            className="bg-[#007AF1] p-2 rounded-lg text-white px-3 hover:bg-blue-700"
            onClick={() => {
              dispatch(logout());
              navigate("/");
            }}
          >
            Kembali ke login
          </button>
        </div>
      </div>
    </div>
  );
};

export default FreezeAccount;
